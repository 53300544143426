// This is configuration file for the typography.js module.
// Some overrides and such defined here.

import Typography from 'typography'
import themeGithub from 'typography-theme-github'
// import Wordpress2016 from 'typography-theme-wordpress-2016'

themeGithub.headerFontFamily = ['Orbitron', 'sans-serif']
themeGithub.bodyFontFamily = ['Raleway', 'sans-serif']
themeGithub.googleFonts = [
  {
    name: 'Orbitron',
    styles: [
      '400',
      '500',
      '700',
      '900',
    ],
  },
  {
    name: 'Raleway',
    styles: [
      '400',
      '500',
      '700',
      '900',
    ],
  }
]

themeGithub.overrideThemeStyles = () => ({
  'a.gatsby-resp-image-link': {
    boxShadow: `none`,
  },
  'blockquote': {
    color: `#61afef`,
  },
})

// ThemeGithub.baseFontSize = '20px'
// delete themeGithub.googleFonts // WHY? Hm. Figure out.
const typography = new Typography(themeGithub)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
