import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import navStyles from "./Navbar.module.css"

const Navbar = ({ siteTitle }) => (
  <div>
    <div
      style={{
        background: `rebeccapurple`,
        height: `0.4rem`,
      }}
    ></div>
    <div
      style={{
        marginBottom: `1.45rem`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0.85rem`,
        }}
      >
        <div style={{ position: `flex`}}>
          <Link to="/" className={navStyles.nav} activeStyle={{ fontWeight: "bold", color: "#cccccc", }}>Home</Link>
          <Link to="/about/" className={navStyles.nav} activeStyle={{ fontWeight: "bold", color: "#cccccc", }}>About</Link>
          <Link to="/contact/" className={navStyles.nav} activeStyle={{ fontWeight: "bold", color: "#cccccc", }}>Contact</Link>
          <Link to="/tags/" className={navStyles.nav} activeStyle={{ fontWeight: "bold", color: "#cccccc", }}>Tags</Link>
        </div>
      </div>
    </div>
  </div>
)

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar